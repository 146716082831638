<template>
  <div
    class="row main-row d-flex flex-row justify-content-between align-items-center"
  >
    <div class="col-12 col-md-6 bg-white d-none d-md-block">
      <div class="d-flex justify-content-center align-items-center main-row">
        <div class="d-flex align-items-center">
          <b-img fluid :src="imgUrl" alt="Login V2" height="500" width="300" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 bg-light">
      <div class="d-flex justify-content-center align-items-center main-row">
        <div class="login-area bg-white px-2 py-4">
          <div class="pt-5">
            <div class="text-center d-block d-md-none">
              <b-img fluid :src="imgUrl" alt="Login V2" />
            </div>

            <b-card-title
              class="gradient-title mb-1 font-weight-bold"
              title-tag="h2"
            >
              Sign In
            </b-card-title>
            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form form-area" @submit.prevent="login">
                <!-- email -->
                <b-form-group>
                  <label class="form-label" for="login-email">Email</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="Type your email"
                      class="py-2"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <label class="form-label" for="login-password"
                    >Password</label
                  >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge py-2"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Type your password"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  class="btn-sign-in w-50 d-block py-1 mt-5 mx-auto"
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  <span>Sign in</span>
                </b-button>
              </b-form>
            </validation-observer>

            <div class="d-flex flex-column text-center mt-5">
              <p class="forgot-link">
                <router-link :to="{ name: 'auth-forgot-password' }">
                  Forgot Password?
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { initialAbility } from "@/libs/acl/config";
import store from "@/store/index";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { email, required } from "@validations";
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  VBTooltip,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

import { toast } from "@/utils/toast";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: "",
      // validation rules
      required,
      email,
    };
  },
  created() {
    if (this.$store.state.appConfig.logo !== null) {
      this.sideImg = this.$store.state.appConfig.logo;
    } else {
      this.getSystemConfig();
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === "dark") {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
      //   return this.sideImg;
      // }
      return this.sideImg;
    },
  },
  methods: {
    getSystemConfig() {
      this.$store.dispatch("appConfig/getSystemConfig").then((res) => {
        this.sideImg = res.logo;
      });
    },
    login() {
      this.$refs.loginForm.validate().then(async (success) => {
        if (success) {
          try {
            let res = await this.$store.dispatch("auth/login", {
              email: this.userEmail,
              password: this.password,
            });
            if (res.status_code == 200) {
              let userData = res.data.user;
              userData.ability =
                res.data?.permissions.length > 0
                  ? res.data?.permissions
                  : initialAbility;

              useJwt.setToken(res.token);
              localStorage.setItem("userData", JSON.stringify(userData));
              this.$ability.update(userData.ability);
              this.$router
                .replace(getHomeRouteForLoggedInUser(userData.roles[0].name))
                .then(() => {
                  toast(
                    `Welcome ${userData.name || userData.username}`,
                    "CoffeeIcon",
                    "success",
                    `You have successfully logged in as ${userData.roles[0].name}. Now you can start to explore!`
                  );
                })
                .catch((error) => {
                  toast(
                    "Error",
                    "AlertTriangleIcon",
                    "danger",
                    "Login Failed!"
                  );
                });
            } else {
              toast("Error", "AlertTriangleIcon", "danger", "Login Failed!");
            }
          } catch (error) {
            let errorResponse = error.response?.data?.error;
            for (const key in errorResponse) {
              if (errorResponse.hasOwnProperty(key)) {
                const errorMessages = errorResponse[key];
                // Loop through each error message in the array
                for (const errorMessage of errorMessages) {
                  toast("Error", "AlertTriangleIcon", "danger", errorMessage);
                }
              }
            }
            // show error message from
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.form-area {
  margin: 100px;
}
.login-area {
  height: 800px !important;
  width: 700px !important;
}

@media only screen and (max-width: 1440px) and (min-width: 1050px) {
  .login-area {
    height: 600px !important;
    width: 450px !important;
  }
  .form-area {
    margin: 40px;
  }
}

.new-account {
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #7190ef;
}

.forgot {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #515b60;
}

.forgot-link {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;
  color: #264296;
  cursor: pointer;
}

.btn-sign-in {
  background: linear-gradient(315deg, #01185e 0%, #264296 44.92%, #7190ef 100%);
  border-radius: 5px;
}

.btn-sign-in span {
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  color: #ffffff;
}

#login-email,
#login-password {
  background: #f9f9f9;
  border-radius: 4px;
}

.main-row {
  height: 100vh !important;
  overflow: hidden !important;
}
</style>
